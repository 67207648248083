<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="goTo('/goods/service/add')">添加商品服务</a-button>
    </div>
    <a-table class="doc-table" :dataSource="dataSource" :columns="columns" :pagination="false">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'image'">
          <a-avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
        </template>
        <template v-if="column.key === 'sort'">
          <a-input-number v-model:value="record.sort" :min="0" :max="100000" />
        </template>
        <template v-if="column.key === 'action'">
          <a-space>
            <a-button type="link" size="small" @click="goTo('/goods/service/edit')">编辑</a-button>
            <a-button type="link" size="small" danger>删除</a-button>
          </a-space>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    const goTo = (path) => {
      router.push(path);
    };

    onMounted(() => {});

    return {
      goTo,
      dataSource: ref([
        {
          service_name: "七天无理由退货",
          image: "",
          description: "七天无理由退货七天无理由退货",
          sort: 0,
        },
        {
          service_name: "质量保障",
          image: "",
          description: "正品质量保障假一赔十",
          sort: 0,
        },
      ]),
      columns: [
        {
          title: "商品服务",
          dataIndex: "service_name",
          key: "service_name",
          width: "20%",
          rowDrag: true,
        },
        {
          title: "图标",
          dataIndex: "image",
          key: "image",
          width: "10%",
        },
        {
          title: "描述",
          dataIndex: "description",
          key: "description",
          width: "40%",
        },
        {
          title: "排序",
          dataIndex: "sort",
          key: "sort",
          width: "10%",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: "20%",
        },
      ],
    };
  },
});
</script>